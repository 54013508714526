.bulk-orders {
  .status-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 11px;
    letter-spacing: 0.3px;
  }

  .processing {
    color: var(--blue-800);
    background-color: var(--blue-100);
  }

  .processed {
    color: var(--green-800);
    background-color: var(--green-100);
  }

  .processed_with_errors {
    color: var(--orange-800);
    background-color: var(--orange-100);
  }

  .processed_with_warnings {
    color: var(--yellow-800);
    background-color: var(--yellow-100);
  }

  .failed {
    color: var(--pink-800);
    background-color: var(--pink-100);
  }
}

.import-result {
  text-align: center;
  padding: 48px 24px;
  border-radius: 3px;
  overflow: hidden;
  background: var(--surface-50);
  box-shadow: 0 2px 10px var(--bluegray-100), 0 2px 2px var(--bluegray-300);

  &.import-failure {
    .status-icon {
      color: var(--red-500);
    }
  }

  &.import-success {
    .status-icon {
      color: var(--green-500);
    }
  }

  .status-icon {
    font-size: 40px;
    width: 50px;
    height: 50px;
  }

  .title {
    margin-top: 15px;
    color: var(--gray-700);
  }

  .subtitle {
    margin-top: 10px;
    color: var(--gray-700);
  }

  .content {
    padding: 24px;
  }
}
