.customers {
  display: flex;
  flex-direction: column;
  .general-section {
    margin: auto;
    min-width: 600px;
    @media screen and (max-width: 768px) {
      min-width: 100%;
    }
  }
  .customers-section {
    display: flex;
    justify-content: space-around;
    gap: 30px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 5px;
    }
  }
}
