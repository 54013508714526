.bulk-orders-file-importer {
  .upload-file {
    .field-with-errors {
      min-height: unset;
    }

    & > div > div > div {
      display: flex;
      align-items: flex-start;

      .p-fileupload-row > div {
        padding-bottom: 0;
        padding-top: 0;
      }
    }

    .p-fileupload-buttonbar button {
      display: none;
    }

    .p-fileupload .p-fileupload-buttonbar {
      background: none;
      border: none;
      padding: 0;
    }

    .p-fileupload .p-fileupload-content {
      background: none;
      padding: 0;
      border: none;
    }

    .p-fileupload-row {
      display: flex;
      flex-direction: row;
    }

    .p-fileupload-row div {
      width: 100%;
    }

    .p-fileupload-row div:nth-child(2) {
      display: none;
    }

    .p-fileupload-row div:nth-child(3) {
      width: 20px;
    }

    .p-fileupload-content .p-progressbar {
      width: 0;
    }

    .p-fileupload .p-fileupload-advanced .p-component {
      display: flex;
      align-items: center;
    }
  }

  .import-all-or-nothing {
    display: flex;
    align-items: center;
    margin-top: 24px;
    color: var(--text-secondary-color);
  }
}
