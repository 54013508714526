.view-order-dialog {
  width: 75%;
}

.view-order-page {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 15px;
  align-items: center;

  .info-card-customers {
    display: flex;
    gap: 10px;
    width: 100%;
  }

  .info-card-general {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .info-card {
    flex: 1 0 auto;
    max-width: 100%;
    width: 100%;
    height: 100%;
    box-shadow: 0 2px 10px var(--bluegray-100), 0 2px 2px var(--bluegray-300);
    border-radius: 3px;
    padding: 12px;
    background: var(--surface-50);
    padding-top: 20px;
    color: var(--surface-900);

    div.p-d-flex.p-jc-start.p-ai-center {
      margin-bottom: 10px;
      align-items: start;
      h3 {
        margin-top: 1px;
      }
    }

    .title-icon {
      font-size: 20px;
      margin: 0 10px 0 0;
      color: var(--primary-color);
    }

    .table-wrapper {
      overflow-x: auto;
      .payments-table {
        width: 100%;
        margin: auto;

        td {
          margin: 0;

          &:not(:first-child) {
            padding: 6px 10px;
            text-align: center;
          }
        }

        .service-type {
          font-weight: 500;
        }
      }
    }
  }

  .info-card-customer {
    @extend .info-card;
    width: 48%;
    height: auto;
  }

  .info-header {
    width: 180px;
    font-weight: 500;
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    font-size: 12px;
    .info-card-general {
      grid-template-columns: repeat(1, 1fr);
    }
    .info-card-customers {
      flex-direction: column;
    }
    .info-card-customer {
      width: 100%;
    }
  }
}
