%sideMenuShared {
  padding: 0;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 48px);
  margin-top: 48px;
  position: fixed;
  background-color: white;
  z-index: 900;
}

.sideMenuExpanded {
  @extend %sideMenuShared;
  width: 20rem;
  transition: width 0.3s ease-in-out;

  .title {
    text-align: center;
    color: var(--bluegray-700);
    background: var(--bluegray-50);
  }
}

.sideMenu {
  @extend %sideMenuShared;
  width: 5rem;
  transition: width 0.3s ease-in-out;
  box-shadow: 1px 1px 1px var(--surface-100);

  .title {
    text-align: center;
    color: var(--bluegray-700);
    background: var(--bluegray-50);
  }
}

.sideMenuMobile {
  padding: 0;
  width: 25rem;
  max-width: 100%;
  overflow-y: auto;
  z-index: 1000000;
  padding-bottom: 6.5rem;

  .title {
    text-align: center;
    color: var(--bluegray-700);
    background: var(--bluegray-50);
  }
}

.itemsWrapper {
  margin-bottom: 3.5rem;
}

%menuButtonShared {
  position: fixed;
  bottom: 0;
  background-color: var(--surface-50);
  border: 0;
  border-radius: 0;
  color: var(--surface-700);
  transition: width 0.3s ease-in-out;
  height: 40px;

  &:focus {
    box-shadow: 0 0 0 0 transparent;
  }

  &:hover {
    background: var(--surface-100) !important;
    color: var(--surface-600) !important;
  }
}

.menuButtonExpanded {
  @extend %menuButtonShared;
  width: 20rem !important;
}

.menuButton {
  @extend %menuButtonShared;
  width: 5rem !important;
  padding: 0.5rem 2rem;
  display: block;
}

.sidebarMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
