.formik-stepper {
  max-width: 100%;
  box-shadow: 0 2px 10px var(--bluegray-100), 0 2px 2px var(--bluegray-300);
  border-radius: 3px;
  overflow: hidden;
  margin: auto;
  margin-top: auto;
  background: var(--surface-50);

  & > form > .content {
    margin: 0 0 20px 0;
    padding: 0 24px;
    min-height: 300px;
    max-height: calc(100vh - 325px);
    overflow-y: auto;
    position: relative;
  }

  .title,
  .subtitle {
    display: block;
  }

  .stepper-header {
    padding: 24px;
    display: flex;
    justify-content: space-between;

    .stepper-title {
      color: var(--gray-800);
      font-size: 18px;
      font-weight: 600;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .step-indicator {
      margin-left: 30px;
      font-size: 14px;
      font-weight: normal;
      color: var(--bluegray-500);
    }
  }

  .footer {
    padding: 24px;
    background-color: var(--bluegray-50);
    border-top: 1px solid var(--bluegray-100);
  }

  .nav-buttons-right {
    gap: 16px;
  }

  .step-dot {
    width: 6px;
    height: 6px;
    margin: 5px;
    border-radius: 10px;
    background: var(--surface-300);

    &.active {
      background: var(--surface-500);
      width: 10px;
      height: 10px;
    }
  }

  .title-and-subtitle-section {
    margin-bottom: 18px;
  }

  .section-title {
    margin: 16px 0 8px 0;
    width: 100%;
    display: block;
    font-size: 16px;
    font-weight: bold;
  }

  .section-subtitle {
    margin: 0 0 20px 0;
    width: 100%;
    display: block;
    font-size: 14px;
    color: var(--surface-500);
    font-weight: 300;
  }
}
