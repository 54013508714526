@mixin columnLayout($columnsPerRow) {
  display: grid;
  align-items: baseline;
  grid-template-columns: repeat($columnsPerRow, minmax(0, 1fr));
  column-gap: 1rem;
}

.fourColumnLayout {
  @include columnLayout(4);
}

// For mobile
.twoColumnLayout {
  @include columnLayout(2);
}
