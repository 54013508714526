.notifications {
  background-color: white;
  margin: auto;
  padding: 24px;
  border-radius: 3px;
  box-shadow: 0px 5px 10px var(--bluegray-100), 0 2px 2px var(--bluegray-300);
  width: 720px;
  max-width: 100%;

  .subtitle {
    margin-bottom: 20px;
  }

  .description {
    font-style: italic;
    font-weight: 300;
    letter-spacing: 1px;
  }

  table {
    width: 100%;
    table-layout: auto;

    td,
    th {
      padding: 10px 5px;
      text-align: center;
      color: var(--bluegray-800);

      &:first-child {
        text-align: left;
      }
    }

    tr {
      background: white;
      &:nth-child(odd),
      th {
        background: var(--bluegray-50);
      }
    }
  }

  @media screen and (min-width: 768px) {
    table {
      td,
      th {
        height: auto;
        padding: 10px;
      }
    }
  }
}
