.dialog-stepper {
  &.p-dialog {
    .p-dialog-header {
      padding: 20px;

      .p-dialog-title {
        display: flex;
        align-items: center;

        .step-indicator {
          margin-left: 20px;
          font-size: 14px;
          font-weight: normal;
          color: var(--surface-500);
        }
      }
    }

    .p-dialog-content {
      padding: 0 24px 24px 24px;
    }

    .p-dialog-footer {
      padding: 24px;
      background-color: var(--bluegray-50);
      border-top: 1px solid var(--bluegray-100);

      .step-dot {
        width: 6px;
        height: 6px;
        margin: 5px;
        border-radius: 10px;
        background: var(--surface-300);

        &.active {
          background: var(--surface-500);
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  .title-and-subtitle-section {
    margin-bottom: 18px;
  }

  .section-title {
    margin: 8px 0;
    width: 100%;
    display: block;
    font-size: 16px;
    font-weight: bold;
  }

  .section-subtitle {
    margin: 0 0 20px 0;
    width: 100%;
    display: block;
    font-size: 14px;
    color: var(--surface-500);
    font-weight: 300;
  }
}
