.bulk-order-import-log-dialog {
  width: 90%;
}

.imported-lists-view-import-log {
  .log-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .info-title {
      width: 80px;
    }

    .info-value {
      margin: 0 0 0 10px;
      font-weight: 600;
    }
  }
}

.import-log-dt .warning {
  background-color: var(--yellow-100) !important;
}

.import-log-dt .error {
  background-color: var(--pink-100) !important;
}
