.bulk-orders-file-importer {
  .variable-payments {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 12px;

    .field-with-errors {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      label {
        width: 140px;
      }
    }

    .p-field {
      margin-bottom: 0;
    }

    .p-selectbutton {
      margin: 0 40px;
    }

    .p-fluid .p-buttonset {
      display: block;
    }
  }
}
