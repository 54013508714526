.qrcode-generate {
  .qrcode-generate-body {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-items: center;
    gap: 10px;
    background-color: white;
    margin: auto;
    padding: 24px;
    border-radius: 3px;
    box-shadow: 0px 5px 10px var(--bluegray-100), 0 2px 2px var(--bluegray-300);
    width: 720px;
    max-width: 100%;

    h4 {
      margin: 0 0 4px 0;
      color: var(--blue-500);
    }

    .qr-code {
      width: 256px;
      height: auto;
    }

    .qr-code-description {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: large;
      gap: 5px;
      margin: 16px;
    }
  }
}
