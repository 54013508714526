.order-action-result {
  text-align: center;
  padding: 48px 24px;
  border-radius: 3px;
  overflow: hidden;

  &.result-error {
    .status-icon {
      color: var(--red-500);
    }
  }

  &.result-success {
    .status-icon {
      color: var(--green-500);
    }
  }

  .status-icon {
    font-size: 40px;
    width: 50px;
    height: 50px;
  }

  .title {
    margin-top: 15px;
    color: var(--gray-700);
  }

  .subtitle {
    margin-top: 10px;
    color: var(--gray-700);
  }

  .content {
    padding: 24px;
  }

  .order-result-button {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-top: 2rem;
  }

  @media only screen and (max-width: 768px) {
    .order-result-button {
      grid-template-columns: 1fr;
    }
  }
}
