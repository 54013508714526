.menu {
  padding: 0;
  top: 0px;
  left: 0px;
  bottom: 0px;

  .menu-links {
    .menu-link > div {
      padding: 18px 16px;

      &:hover {
        background-color: var(--bluegray-50);
        cursor: pointer;

        &:not(.active) {
          * {
            color: var(--bluegray-900);
          }
        }

        &.active {
          cursor: default;
        }
      }
    }

    .link-icon {
      font-size: 16px;
      width: 30px;
      margin-right: 8px;
      color: var(--bluegray-700);
    }

    .link-label {
      color: var(--bluegray-700);
    }

    .active > div {
      padding-left: 12px;
      border-left: 4px solid var(--bluegray-600);
      & {
        background-color: var(--bluegray-100);
      }
      &:hover {
        width: 100%;
        background-color: var(--bluegray-100);
        border-left: 4px solid var(--bluegray-600);
      }
    }
  }
}

body .p-sidebar {
  padding: 0;
}

.mobile-menu {
  height: auto;
  margin: 0;
  overflow-y: visible;
  min-width: 250px;
}

.desktop-menu {
  &-expanded {
    overflow-y: auto;
    min-width: 250px;
  }

  &-collapsed {
    overflow-y: auto;
    width: 5rem;
  }
}
