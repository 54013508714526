.inline-stepper {
  padding: 10px;
  overflow: auto;
  .stepper-content {
    height: 100%;
  }

  .title,
  .subtitle {
    display: block;
  }

  .step-header {
    border-bottom: 1px solid var(--bluegray-100);
  }

  .footer {
    padding: 14px;
    background-color: var(--bluegray-50);
    border-top: 1px solid var(--bluegray-100);
  }

  .nav-buttons-right {
    gap: 16px;
  }

  .step-dot {
    width: 6px;
    height: 6px;
    margin: 5px;
    border-radius: 10px;
    background: var(--surface-300);

    &.active {
      background: var(--surface-500);
      width: 10px;
      height: 10px;
    }
  }

  .title-and-subtitle-section {
    margin-bottom: 18px;
  }

  .section-title {
    margin: 8px 0;
    width: 100%;
    display: block;
    font-size: 16px;
    font-weight: bold;
  }

  .section-subtitle {
    margin: 0 0 20px 0;
    width: 100%;
    display: block;
    font-size: 14px;
    color: var(--surface-500);
    font-weight: 300;
  }
}
