.shipments-page {
  .datatable-responsive {
    .p-datatable-tbody > tr > td {
      display: none;
    }

    .p-datatable-thead > tr > th,
    .p-datatable-tfoot > tr > td {
      display: none !important;
    }

    .p-datatable-tbody tr {
      border-bottom: 1px solid #e9ecef;

      display: inline-block;
      &:last-child {
        border-bottom: none;
      }
    }

    .p-datatable-tbody > tr > td {
      display: table-cell;
      border: 0 none;
    }
  }

  .p-datatable-thead .selection-column .p-column-header-content > div {
    display: none;
  }
}
