.profile-page {
  background-color: white;
  margin: auto;
  padding: 24px;
  border-radius: 3px;
  box-shadow: 0px 5px 10px var(--bluegray-100), 0 2px 2px var(--bluegray-300);
  max-width: 720px;

  .change-pass-label {
    color: var(--bluegray-300);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  .place-wrapper {
    display: flex;
    align-items: center;

    .zip-code {
      margin-right: 8px;
      width: 64px;
    }

    .p-dropdown {
      flex: 1;
      min-width: unset;
    }
  }

  .street-numbers {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 14px;
  }

  .p-disabled,
  .p-component:disabled {
    background-color: var(--surface-200);
  }
}
