:root {
  // TODO: remove this after primereact v8 upgrade
  --red-500: #d32f2f;
}

html,
body {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: var(--blue-500);

  &:hover {
    color: var(--blue-700);
  }
}

.sidebar_filter {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  &_row {
    display: flex;
    gap: 0.5rem;
  }

  & > label {
    margin-bottom: 3px;
    font-size: smaller;
  }

  &_active {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--yellow-100);
    border-color: var(--yellow-500) !important;
  }
}

.sidebar_filter_same_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.page {
  background: #fafafa;
  padding: 8px 5px;
  box-shadow: -1px 0px 2px 0px white;
  border-radius: 5px;
}

.content-wrapper {
  padding: 60px 10px 10px 10px;
  flex-grow: 1;
  top: 0;
  right: 0;
  bottom: 0;
  transition: left 0.3s ease-in-out;
  background: var(--bluegray-100);
  height: 100%;

  @media screen and (max-width: 768px) {
    padding-bottom: 0px;
  }
}

.p-checkbox + label {
  margin-left: 8px;
}

.unselectable {
  user-select: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  color: var(--bluegray-700);
}

.form-divider {
  margin-top: 48px;
}

.title-go-back-link {
  display: inline-block;
  cursor: pointer;

  &,
  &:visited {
    color: var(--surface-500);
  }

  &:hover {
    color: var(--primary-color);
  }

  &:active {
    color: var(--primary-color);
  }
}

.main-btn {
  margin-bottom: 16px !important;

  &:not(:last-of-type) {
    margin-right: 8px;
  }
}

.p-field {
  .p-grid .p-field {
    padding-bottom: 0;
  }

  .p-field {
    margin-bottom: 0;
  }
}

.p-fluid .p-grid [class*='p-col'] {
  padding-bottom: 0;
}

.public-route {
  padding: 24px 24px 36px 24px;
}

.accordionHeaderTemplate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transform: translateY(-1px);
}

.badge {
  border-radius: 10px;
  text-align: center;
  padding: 0 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  background: var(--yellow-500);
  color: #212529;
}
