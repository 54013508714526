.formWrapper {
  margin: 4rem auto;
  max-width: 480px;
  background: white;
  border-radius: 3px;
  overflow: hidden;

  .logoWrapper {
    margin-top: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 80%;
      height: auto;
      object-fit: cover;
      padding: 1rem;
      pointer-events: none;
      user-select: none;
    }
  }

  .error {
    color: var(--pink-500);
    background: var(--pink-50);
    border: 1px solid var(--pink-500);
    border-radius: 3px;
    text-align: center;
    font-weight: bold;
  }
}
