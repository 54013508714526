.bulk-orders-web-importer {
  .variable-payments {
    width: 100%;
    max-width: 480px;

    .rhf-field-with-errors {
      display: grid;
      grid-template-columns: 140px auto;
      align-items: center;
      row-gap: 12px;

      label {
        margin: 0;
      }
    }

    .p-fluid .p-buttonset {
      display: block;
    }
  }

  .content {
    table {
      display: block;
      width: 100%;
      height: auto;
      overflow-x: auto;
      overflow-y: hidden;
      border-collapse: collapse;
      margin: 20px 0 12px 0;
      padding-bottom: 8px;

      tr {
        th {
          text-align: left;
          color: var(--bluegray-500);
          font-size: 12px;
          font-weight: 500;
          padding-bottom: 4px;
          text-indent: 4px;
        }

        td {
          padding: 4px 4px;

          &:not(:first-child) {
            min-width: 100px;
          }
        }

        &:last-child {
          td {
            border-bottom: none;
          }
        }

        &:hover {
          background-color: var(--bluegray-50);

          .delete-row-btn {
            .row-num-delete-btn {
              .p-button-icon-left {
                display: inline;
              }

              .p-button-label {
                display: none;
              }
            }
          }
        }
      }

      .p-dropdown .p-dropdown-label.p-placeholder {
        color: var(--bluegray-200);
      }

      .p-inputtext {
        min-width: 160px;
        margin-right: 8px;

        &::placeholder {
          color: var(--bluegray-200);
        }
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: var(--bluegray-300);
      }

      &::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: var(--surface-100);
      }

      &::-webkit-scrollbar {
        height: 8px;
        background-color: var(--surface-100);
      }
    }

    .delete-row-btn {
      .row-num-delete-btn {
        width: 40px;

        .p-button-icon-left {
          display: none;
        }

        .p-button-label {
          flex: 0 0 0;
        }

        &:hover {
          .p-button-icon-left {
            display: inline;
          }

          .p-button-label {
            display: none;
          }
        }
      }
    }

    .add-row-btn {
      padding-top: 8px;
      max-width: 120px;
    }

    .import-all-or-nothing {
      color: var(--text-secondary-color);
      width: 180px;
    }
  }
}
