// ------------------------
// ------- Defaults -------
// ------------------------

html {
  font-size: 14px;
}

body {
  background: var(--surface-50);
}

.p-datatable-tbody {
  display: table-row-group !important;
  font-size: small;
}

.p-datatable-emptymessage {
  display: table-row !important;

  & > td {
    padding: 24px 16px !important;
    cursor: default;
    background: white !important;

    &:hover {
      background: white !important;
    }
  }
}

.p-contextmenu {
  .p-menuitem-icon {
    &.fas {
      margin: 0 14px 0 4px !important;
    }
  }
}

.p-datatable {
  box-shadow: 0px 0px 5px var(--surface-200);

  &:not(.p-datatable-gridlines) {
    border-width: 0 1px 0 1px;
    border-style: solid;
    border-color: var(--surface-300);
    border-radius: 3px;
  }

  td {
    overflow-wrap: break-word;
  }
}

.p-datatable-header {
  background: var(--surface-50) !important;
}

.p-datatable .p-datatable-thead > tr > th {
  background: var(--surface-50) !important;
}

.p-datatable-footer {
  background: var(--surface-50) !important;
}

.p-sidebar {
  .p-sidebar-header {
    padding: 0 !important;
  }

  .p-sidebar-header + .p-sidebar-content {
    padding: 0;
  }
}

.p-accordion-filters {
  .p-accordion .p-accordion-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1rem;
    overflow: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .p-accordion-filters {
    .p-accordion .p-accordion-content {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
@media only screen and (max-width: 768px) {
  .p-accordion-filters {
    .p-accordion .p-accordion-content {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.p-accordion-header .p-accordion-header-link {
  padding: 2px !important;
}

// ---------------------------------------------
// ------- Overrides with custom classes -------
// ---------------------------------------------
.p-tabview.invalid {
  .p-highlight a.p-tabview-nav-link {
    border-color: var(--red-500);
  }

  i.fas {
    color: var(--pink-500);
    margin-right: 4px;

    & + .p-tabview-title {
      color: var(--pink-500);
    }
  }
}

.p-inputtext {
  padding: 5px 5px !important;
}
