.emailConfigurationPage {
  margin: 0 auto;
  padding: 24px;
  max-width: 720px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 5px 10px var(--bluegray-100), 0 2px 2px var(--bluegray-300);

  .action {
    &:last-child {
      margin-bottom: 0 !important;
    }

    .recipient {
      max-width: 480px;
    }
  }
}
