.welcome-page {
  .welcome-grid {
    margin: 0 auto;
    max-width: 1440px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    & > div:nth-child(2) {
      max-width: 600px;
    }

    & > div:nth-child(1) {
      @media (max-width: 1299px) {
        display: none;
      }
    }
  }

  h2 {
    @media (min-width: 1300px) {
      margin-top: -72px;
    }
  }

  img {
    width: 600px;
    height: auto;
  }

  p {
    line-height: 1.25;
    font-weight: 300;
  }
}
